<template>
  <div id="segmentListPage">
    <!-- Header -->
    <div class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4">
      <!-- Header With Filters -->
      <div class="bg-white p-2 border-b border-gray-200">
        <UserFilterComponent v-bind:filterTitle="'Segment Filters'" ref="userFilter"></UserFilterComponent>
      </div>

      <div class="bg-white px-3 pt-2 pb-3 pt-3">
        <el-button type="primary" :disabled="submittingQuery" size="small" @click="onShowCount()">Show Count</el-button>
        <el-button type="" plain size="small" @click="onCreateSegment(null)">Save As Segment</el-button>

        <a target="_blank" href="https://support.growlytics.in/customers-and-segments/segmentation/creating-segments" class="float-right">
          <el-button @click="window.Intercom('showArticle', 6400898)" type="primary" plain size="mini">Learn more about segments <i class="el-icon-top-right"></i></el-button>
        </a>
      </div>
    </div>

    <!-- Query List -->
    <div class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4" v-if="queryList && queryList.length > 0">
      <!-- Header With Filters -->
      <div class="bg-white" v-if="queryList.length > 0">
        <!-- Header -->
        <div class="flex bg-gray-50 w-full border-b">
          <div class="flex-none w-8">&nbsp;</div>
          <div class="flex-none w-32 py-3.5 pl-1 pr-3 text-left text-sm font-semibold text-gray-900">Query Time</div>
          <div class="flex-grow px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Segment Description</div>
          <div class="flex-none w-36 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Customers</div>
          <div class="flex-none w-20">&nbsp;</div>
        </div>

        <template v-for="(query, index) in queryList">
          <div :key="index" class="border-b">
            <div class="flex w-full cursor-pointer hover:bg-gray-50">
              <div class="flex-none w-8 text-center py-3" @click="onShowSegmentDetails(index)">
                <div class="text-sm" v-if="query.stage == 'complete'">
                  <i class="el-icon-arrow-down" v-if="currentActive == index" />
                  <i class="el-icon-arrow-right" v-if="currentActive != index" />
                </div>
              </div>
              <div class="flex-none w-32 whitespace-nowrap py-3 pl-1 pr-3 text-xs font-base text-gray-600 text-left leading-5 align-top" @click="onShowSegmentDetails(index)">
                {{ moment(query.query_time, 'x').format('Do MMM YYYY') }} <br />
                {{ moment(query.query_time, 'x').format('hh:mm A') }}
              </div>
              <div class="flex-grow px-3 pr-5 py-3 text-gray-600 align-top" @click="onShowSegmentDetails(index)">
                <div style="font-size: 13px; line-height: 20px" v-html="query.text" />
              </div>
              <div class="flex-none w-36 whitespace-nowrap text-sm px-3 py-3 text-gray-500 align-top align-top" @click="onShowSegmentDetails(index)">
                <template v-if="query.stage == 'running'">
                  <i class="fa fa-refresh fa-spin text-md text-primary-400"></i>
                </template>
                <span v-if="query.stage == 'complete'">{{ getNumberWithComma(query.result.total) }}</span>
                <span class="text-red-500 text-xs font-bold" v-if="query.stage == 'failed'">FAILED</span>
              </div>
              <div class="flex-none w-20 whitespace-nowrap py-3 pl-3 pr-4 text-right text-sm font-medium text-primary-600 align-top">
                <a class="cursor-pointer text-primary-600" v-if="query.stage != 'running'" @click="executeExistingQuery(index)">
                  <el-tooltip content="Run Query" placement="left">
                    <!-- <i class="el-icon-refresh-right text-lg"></i> -->
                    <i class="text-sm fa-regular fa-arrow-rotate-right"></i>
                  </el-tooltip>
                </a>
                <span v-else>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                &nbsp;&nbsp;&nbsp;
                <!-- Options Dropdown -->
                <el-dropdown @command="onSegmentOptionClick(index, ...arguments)">
                  <span class="el-dropdown-link text-primary-600 text-base"><i class="el-icon-more" style="transform: rotate(90deg)"></i> </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="edit"> <i class="fa-sharp fa-solid fa-pen text-primary-600"></i> Edit Query </el-dropdown-item>
                    <el-dropdown-item command="save" divided> <i class="fa-sharp fa-solid fa-bookmark text-primary-600"></i> Save As Segment </el-dropdown-item>
                    <el-dropdown-item command="export"> <i class="fa-solid fa-cloud-arrow-down text-primary-600"></i> Export Users </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                &nbsp;&nbsp;
              </div>
            </div>

            <!-- Segment Details -->
            <div class="bg-gray-50 mb-3 mx-10 border p-3 rounded-md" v-if="index == currentActive && query.stage == 'complete'">
              <div class="grid grid-cols-4 gap-4">
                <div>
                  <div class="text-xs text-gray-500">Total Customers</div>
                  <div class="text-md text-gray-800">{{ getNumberWithComma(query.result.total) }}</div>
                </div>
                <div>
                  <div class="text-xs text-gray-500">Registered Customers</div>
                  <div class="text-md text-gray-800">{{ getNumberWithComma(query.result.registered) }}</div>
                </div>
                <div>
                  <div class="text-xs text-gray-500">Reachablity On Email</div>
                  <div class="text-md text-gray-800">{{ getNumberWithComma(query.result.reachablity.email) }}</div>
                </div>
                <div>
                  <div class="text-xs text-gray-500">Reachablity On SMS</div>
                  <div class="text-md text-gray-800">{{ getNumberWithComma(query.result.reachablity.sms) }}</div>
                </div>
                <div>
                  <div class="text-xs text-gray-500">Reachablity On WhatsApp</div>
                  <div class="text-md text-gray-800">{{ getNumberWithComma(query.result.reachablity.whatsApp) }}</div>
                </div>
                <div>
                  <div class="text-xs text-gray-500">Reachablity On Web Push</div>
                  <div class="text-md text-gray-800">{{ getNumberWithComma(query.result.reachablity.webPush) }}</div>
                </div>
                <div>
                  <div class="text-xs text-gray-500">Reachablity On Mobile Push</div>
                  <div class="text-md text-gray-800">{{ getNumberWithComma(query.result.reachablity.mobilePush) }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <ExportSegmentUsersPopup ref="exportSegmentUsersPopup"></ExportSegmentUsersPopup>
  </div>
</template>
<style lang="scss" src="./segmentQueryStudio.scss"></style>

<script>
import segmentQueryStudioComponent from './segmentQueryStudioComponent';
export default segmentQueryStudioComponent;
</script>
